.checkbox {
	@apply inline-flex items-start;
	&:hover,
	&:focus {
		input {
			@apply bg-g-100/75;
		}
	}

	[type='checkbox']:checked {
		@apply bg-black-rt;
	}

	input {
		@apply relative h-5 w-5 rounded-md cursor-pointer border border-g-200 mt-1 transition-colors duration-300;
		-webkit-appearance: none;
		appearance: none;
		&:checked {
			@apply bg-black-rt border-black-rt;
		}

		&:focus:not(:focus-visible) {
			@apply ring-transparent;
		}
	}

	input {
		&:checked {
			background-image: url('/images/svg/check.svg');
			background-size: 60%;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&.icon-lock {
		input {
			&:checked {
				background-image: url('/images/svg/lock.svg');
				background-size: 50%;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	label {
		@apply pl-1.5 mt-1 cursor-pointer select-none;
	}
}

// Used on Product Builder
.card-style {
	@apply flex flex-row-reverse justify-between items-center 
	 	w-full px-1 py-px rounded-lg font-medium text-g-500 
		ring-1 ring-g-200 hover:ring-black-rt;

	&:has(input:checked) {
		@apply text-black-rt ring-2 ring-black-rt;
	}

	label {
		@apply flex-grow;
	}

	input {
		@apply rounded-full mr-1 -mt-px w-3.5 h-3.5;
	}
}
