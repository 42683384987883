// Icon
// -------------------------

.icon {
	display: inline-block;
	fill: currentColor;
	flex-shrink: 0;
	height: 1em;
	pointer-events: none;
	vertical-align: -0.175em;
	width: 1em;
}
