.radio-card {
	@apply inline-flex items-center;

	&.disabled {
		@apply opacity-50 pointer-events-none;
	}

	input {
		-webkit-appearance: none;
		appearance: none;
		height: 0;
		width: 0;
		border: 0;
		&:checked + label::before {
			@apply border-2 border-black-rt bg-black-rt;
		}
		&:checked + label {
			@apply text-white;
			path {
				@apply fill-white;
			}
		}

		&:focus {
			@apply ring-transparent;
		}
		&:focus:not(:focus-visible) {
			@apply ring-transparent;
		}
	}

	label {
		@apply px-5 pt-[0.875rem] pb-[0.625rem] rounded-lg relative cursor-pointer bg-white;

		&:hover {
			&::before {
				@apply bg-g-100/75;
			}
		}
		&::before {
			content: '';
			@apply rounded-lg absolute inset-0;
			@apply border border-g-200;
		}
		span {
			@apply relative text-[13px] font-medium;
		}
	}
}
