// default: dark medium button
.button {
	@apply relative
		inline-flex
		items-center
		justify-center
		font-medium
		border
		border-transparent
		pt-[10px]
		pb-[6px]
		px-4
		rounded-full
		cursor-pointer
		overflow-hidden;
	transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;

	&:disabled {
		@apply cursor-not-allowed opacity-50;
	}

	.right-arrow-hover {
		@apply pl-3 transition-transform duration-300 ease-in-out;
	}
	&:hover {
		.right-arrow-hover {
			@apply translate-x-1;
		}
	}
}

.button-sm {
	@apply text-[13px] pt-[6px] pb-[2px] px-3;
}

.button-md {
	@apply pt-[9px] pb-[5px] p-5;
	&:has(svg) {
		@apply py-[7px];
	}
}

.button-lg {
	@apply text-lg pt-[13px] pb-[9px] px-8;
	svg {
		@apply text-sm;
	}
}

// yellow button
.button-accent {
	@apply bg-yellow-rt text-black-rt;
}

// black button
.button-dark {
	@apply bg-black-rt text-white;

	&:hover {
		@apply bg-black-rt/80;
	}
}

// white button
.button-light {
	@apply bg-white text-black-rt border-white;

	&:hover {
		@apply bg-g-100;
	}
}

.button-outline {
	@apply border border-g-200;
	&:hover {
		@apply border-black-rt/80;
	}
}

// Transparent button
.button-secondary {
	@apply font-medium rounded-none;
}

.button--alt {
	@apply bg-white rounded-lg ring-1 ring-inset ring-g-200 normal-case font-sans font-medium tracking-normal;
	&:hover,
	&:focus {
		@apply bg-g-100 ring-g-300;
	}
	&:active {
		@apply text-g-500;
	}
}

.button--alt2 {
	@apply bg-transparent rounded-lg ring-2 ring-inset ring-black-rt font-semibold 
    font-condensed tracking-wider uppercase;
	&:hover,
	&:focus {
		@apply bg-g-100;
	}
	&:active {
		@apply text-g-500;
	}
}

.button-group {
	@apply flex
		flex-wrap
		gap-4;
}

.button-spinner {
	@apply mr-2;

	.button-sm & {
		@apply w-4 h-4 -mt-0.5;
	}

	.button-md & {
		@apply w-6 h-6 -mt-1;
	}

	.button-lg & {
		@apply mt-0;
	}
}
