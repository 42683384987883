// Icon Button
// -------------------------

.iconButton {
	@apply bg-black-rt rounded-full text-white;
	@apply text-sm h-8 w-8 flex-shrink-0;
	@apply flex items-center justify-center;
	transition: 0.3s opacity ease;
	&:hover,
	&:focus {
		@apply opacity-50;
	}
	&:focus:not(:focus-visible) {
		@apply opacity-100;
	}
	&:focus-visible {
		@apply opacity-50;
	}
}
