.hover-tooltip {
	@apply group-hover:opacity-100 opacity-0;
}

.help-tooltip {
	@apply text-xs rounded-lg absolute group-hover:-translate-y-[calc(100%_+_8px)] -translate-y-[calc(100%_+_6px)] -translate-x-1/2 left-2 p-2 pb-1 w-max  shadow-md border z-20 transition-[opacity,transform] duration-300 ease-out;

	@apply after:content-[''] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:w-0 after:h-0 after:border-4 after:border-transparent  after:border-l-4 after:border-r-4 after:z-10 after:rotate-180;

	@apply bg-white text-black-rt after:border-b-white;

	&.center-tooltip {
		@apply top-0 -translate-y-[calc(100%+6px)] -translate-x-1/2 left-1/2;
	}

	&.dark {
		@apply bg-black-rt text-white after:border-b-black-rt;
	}
}

.help-tooltip-shift-left {
	@apply -translate-x-3/4 after:left-3/4;
}
