// Input
// -------------------------

.input {
	@apply relative overflow-hidden;
	&:focus-within {
		label {
			transform: none;
			opacity: 1;
		}
	}
	*::selection {
		@apply bg-yellow-mesh;
	}

	label {
		@apply block absolute top-[0.5rem] left-4 
		font-normal text-sm text-g-500 
		whitespace-nowrap pointer-events-none;
		opacity: 0.8;
		transform: scale(1.125) translate(0.25rem, 0.5rem);
		transform-origin: top left;
		transition: 0.15s opacity ease, 0.15s transform ease;
	}

	input,
	select,
	textarea {
		@apply w-full font-normal rounded-lg border-g-200 focus:border-black-rt pt-[1.5rem] pb-[0.125rem] px-4 focus:ring-0;

		&:disabled {
			@apply opacity-50;
		}
	}
}

.select {
	@apply h-full w-full transition-colors duration-300;
}

.select-open {
	@apply border-black-rt;
}

.required-text {
	@apply text-xs ml-4 text-g-500;
}

// ----- MODIFIERS ----- //

.input--error {
	@apply text-error z-10;
	&:focus-within {
		@apply text-black-rt;
	}
	input,
	select {
		@apply border-error;
	}
	label {
		opacity: 1;
	}
}

.input-error-message {
	@apply text-error text-xs mt-1;
}

.input--padRight {
	input {
		@apply pr-[6.25rem];
	}
}

input[type='number'] {
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input--number {
	@apply text-center;
	input {
		@apply text-center;
		&:disabled {
			@apply border-warm-gray-500 bg-warm-gray-200 text-black-rt/50;
		}
	}
	label {
		margin: 0 auto;
		right: 0;
		left: 0;
	}

	.disabled & {
		label {
			@apply text-black-rt/50;
		}
	}

	&:not(.align-left) {
		input {
			@apply px-0;
		}
	}

	&.align-left {
		@apply text-left;
		input {
			@apply text-left;
		}
		label {
			@apply ml-4;
		}
	}
}

.input--reset {
	label {
		opacity: 1;
		transform: none;
	}
}

.input--noRounding {
	input,
	select {
		@apply rounded-none;
	}
}

.input--noLabel {
	input {
		@apply py-4;
	}
}

// Subcomponents

.input--boxStyle {
	input,
	select {
		@apply rounded-none focus:ring-0 border-g-200 focus:border-g-200;
	}
}

.input-phone {
	&:has(.disabled) {
		@apply opacity-50;
	}

	.react-international-phone-country-selector-button {
		height: 100% !important;
		padding-inline: 10px;
		border-top-left-radius: 0.5rem !important;
		border-bottom-left-radius: 0.5rem !important;
		border: 0;
		border-right: 1px solid;
		@apply border-g-200;
		background-color: white;
	}

	&:has(.input--error) {
		.react-international-phone-country-selector-button {
			@apply border-error;
		}
	}

	.react-international-phone-input {
		@apply h-[52px] rounded-[0.5rem] w-full border-l-0 rounded-bl-none rounded-tl-none ring-0 focus:border-g-200 text-base font-sans pt-[25px] pl-3 focus:border-g-200 border-none;
	}
}
