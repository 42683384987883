
.dropdown {
	@apply absolute
			w-screen
			-translate-y-px
			left-0
			bg-g-50
			shadow-lg 
			transition-opacity
			duration-300
			ease-out
			opacity-0
			pointer-events-none;
	top: calc(100% - 1px);

	.is-current & {
		@apply opacity-100 pointer-events-auto;
	}
}

// Nav
.nav {
	@apply z-50
			w-full
			fixed
			top-0;
	transition: transform 0.5s ease 0.1s, color 0.5s ease 0.1s, background-color 0.5s ease 0.1s;

	.dropdown {
		@apply w-full;
	}

	&.is-scrolled {
		&.scrolling-down {
			@apply -translate-y-[105%];

			.dropdown,
			.dropdown.is-current {
				@apply opacity-0 pointer-events-none;
			}
		}
	}

	&.nav--staticWide {
		@screen lg {
			position: relative;
			&.is-scrolled {
				transform: none;
			}
		}
	}

	.mobile-nav {
		@apply flex
				flex-col
				pt-[70px]
				justify-between
				space-y-4
				w-screen
				fixed
				inset-0
				-translate-y-full
				overflow-y-auto
				pointer-events-none
        lg:hidden
				-z-10;
		transition: transform 0.9s cubic-bezier(0.23, 1, 0.32, 1);
		height: 100dvh;

		&.is-current {
			@apply translate-y-0 pointer-events-auto;
		}
	}

	.hamburger-button {
		@apply relative w-9 h-9 p-2 -mt-1.5 lg:hidden;

		&:before,
		&:after {
			@apply bg-black-rt w-6 h-[2px] absolute top-1/2 left-[calc(50%-12px)] 
				origin-center content-empty rounded-sm transition duration-300 ease-in-out;
			&.text-contrast-light {
				@apply bg-white;
			}
		}
		&:before {
			@apply -translate-y-1;
		}
		&:after {
			@apply translate-y-1;
		}

		&.toggled {
			&:before {
				@apply rotate-45 translate-y-0;
			}
			&:after {
				@apply -rotate-45 translate-y-0;
			}
		}
	}
}

.mobile-drawer {
	@apply fixed inset-0 bg-black-rt
	mt-16
	flex flex-col
	w-screen overflow-y-auto
	transition-transform ease-in-out;
}

.cart-with-content-indicator {
	@apply relative;
	// yellow dot
	&::after {
		@apply absolute top-[4px] w-[7px] h-[7px] bg-yellow-mesh rounded-full z-10;
		content: '';
	}
	svg {
		@apply -mb-[5px];
	}
}
