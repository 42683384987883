.truncate-multi {
	@apply overflow-ellipsis overflow-hidden whitespace-normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 3;
}

.truncate-split {
	@apply overflow-ellipsis overflow-hidden whitespace-normal;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-webkit-line-clamp: 7;
}
