.link {
	@apply underline py-1;
	transition: color 0.15s ease;
	&:hover,
	&:focus {
		@apply text-gray-500 no-underline;
	}
	&:focus:not(:focus-visible) {
		@apply text-black-rt underline;
		&:hover {
			@apply text-gray-500 no-underline;
		}
	}
	&:focus-visible {
		@apply text-gray-500 no-underline;
	}
	&:active {
		@apply text-yellow-rt;
	}
}
