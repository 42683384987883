// TAB
// All caps with line
.tab {
	@apply relative
		font-medium
		text-lg
		px-3
		py-1
		whitespace-nowrap
		z-10;
	transition: color 0.3s ease, background-color 0.3s ease;

	&:after {
		content: '';
		@apply absolute
			bottom-0
			left-3		
			right-3		
			h-[1.5px]
			rounded-md
			bg-black-rt
			scale-x-0;
		transform-origin: bottom right;
		transition: transform 0.3s ease;
	}

	svg {
		transition: transform 0.3s ease-out;
	}

	&:hover,
	&:focus {
		&:after {
			@apply scale-x-100;
			transform-origin: bottom left;
		}
		.chevron-animated-container {
			@apply translate-y-0;
		}
	}
	.is-current &,
	&.is-current {
		&:after {
			@apply scale-x-100 bg-gray-700;
		}
		.chevron-animation {
			@apply -translate-y-0.5;
		}
	}
}
