// Card
// -------------------------

.card {
	@apply snap-start border border-warm-gray-400 rounded-lg overflow-hidden transition-colors duration-300;

	&.hoverable {
		@apply hover:border-black-rt;
	}

	.card-item {
		@apply flex flex-col;
		.key {
			@apply text-[13px];
		}
		.value {
			@apply font-medium -mt-0.5;
		}
	}
}

// ----- SUBCOMPONENTS ----- //

.card-head {
	@apply bg-warm-gray-100 p-4 pb-[0.625rem] font-medium text-lg border-t  border-warm-gray-400 first:border-t-0;
}
