// Modal
// -------------------------

.modal {
	@apply relative flex flex-col w-full max-w-6xl bg-white max-h-[calc(100dvh_-_20px)] sm:max-h-[calc(100dvh_-_180px)] overflow-x-hidden rounded-[40px] shadow-lg px-6 lg:px-8 xl:px-10;
}

// ----- MODIFIERS ----- //

.modal--wide {
	@apply w-full max-w-6xl xl:mx-auto md:h-[700px];
}

.modal--content-width {
	@apply w-auto max-w-3xl;
}

.modal--split {
	@apply px-0 md:h-[700px] md:min-w-[540px] md:overflow-hidden;
	.left-section {
		@apply flex-grow md:max-h-[calc(100dvh_-_180px)] px-8 md:px-20 py-10 md:py-[86px] md:overflow-y-auto;
	}
	.right-section {
		@apply md:w-[350px] md:h-full md:max-h-[calc(100dvh_-_180px)] flex flex-col bg-warm-gray-100 py-10 md:pt-[86px] md:pb-[72px] px-8 sm:px-10 md:overflow-y-auto shrink-0;
	}
}

// ----- SUBCOMPONENTS ----- //

.modal-close {
	@apply sticky ml-auto w-fit;
	font-size: 1.25rem;
	padding: 0.5rem;
	right: 0.25rem;
	top: 0.75rem;
	transition: 0.15s opacity ease;
	z-index: 20;
	&:hover,
	&:focus-visible {
		@apply opacity-50;
	}
	@screen sm {
		top: 1rem;
		right: 1rem;
	}
}

.modal-scrollable-area {
	@apply overflow-y-auto;

	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		@apply bg-g-200/75;
		border-radius: 4px;
		&:hover {
			@apply bg-g-200;
		}

		.product-reviews & {
			@apply mt-96 md:mt-20 md:mb-16;
		}
		&.product-reviews {
			@apply my-10;
		}
		.order-instant-quote-modal & {
			@apply my-8;
		}
		.filters-modal & {
			@apply mt-[87px] mb-[76px];
			border-radius: 0px;
		}
	}
	&::-webkit-scrollbar-thumb {
		@apply bg-black-rt;
		border-radius: 4px;
	}
}
