.top-tabs-container {
	@apply flex w-full text-g-400 font-medium overflow-x-auto scroll-bar-invisible;
}

.top-tab {
	@apply flex-grow w-full text-center whitespace-nowrap p-4 border-b;

	&.is-active {
		@apply text-black-rt border-b-2 border-black-rt z-10;
	}

	&.dark {
		@apply border-white;
	}
}
