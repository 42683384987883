// Sizes
// -------------------------

.sizes {
	@apply grid grid-cols-5 gap-2 md:gap-4;
	@media (min-width: 30em) {
		@apply grid-cols-6;
	}
}

// ----- MODIFIERS ----- //

.sizes--7 {
	@media (min-width: 35em) {
		@apply grid-cols-7;
	}
}

.sizes--8 {
	@apply md:gap-3 lg:gap-2;
	@media (min-width: 35em) {
		@apply grid-cols-7;
	}
	@media (min-width: 40em) {
		@apply grid-cols-8;
	}
}

.sizes--9 {
	@apply md:gap-2;
	@media (min-width: 35em) {
		@apply grid-cols-7;
	}
	@media (min-width: 40em) {
		@apply grid-cols-9;
	}
}
